import React from 'react';
import { Link } from 'react-router-dom';
import './integrations.scss'; // Import the corresponding SCSS file

const Integrations = () => {
    return (
        <div className="integrations">
            <h2>Our Integrations</h2>
            <div className="integration-list">
                <div className="integration">
                    <h3>Samsung Smart Things</h3>
                    <p>Seamlessly connect with Samsung Smart Things.</p>
                    <Link to="/integrations/samsung-smart-things">Learn More</Link>
                </div>
                <div className="integration">
                    <h3>Tuya Cloud</h3>
                    <p>Integrate easily with Tuya Cloud services.</p>
                    <Link to="/integrations/tuya-cloud">Learn More</Link>
                </div>
            </div>
            <p className="coming-soon">More integrations coming soon!</p>
        </div>
    );
};

export default Integrations;
