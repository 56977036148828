import React from 'react';
import './App.scss';
import Hero from './components/Hero/Hero';
import Pricing from './components/Pricing/Pricing';
import Donation from './components/Donation/Donation';
import Integrations from './components/Integrations/Integrations';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TuyaIntegration from "./components/TuyaIntegration/TuyaIntegration";

function App() {
  return (
      <Router>
      <div className="App">
        <header>
          <Hero />
        </header>
          <Routes>
              <Route path="/integrations/samsung-smart-things" >
                  {/* Samsung Smart Things Documentation Component */}
              </Route>
              <Route path="/integrations/tuya-cloud" Component={TuyaIntegration}>
              </Route>
              {/* Add other routes here */}
          </Routes>
        <main>
          <Pricing />
          <Donation />
            <Integrations />
        </main>
      </div>
      </Router>
  );
}

export default App;
