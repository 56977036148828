import React from 'react';
import './hero.scss'; // Import the corresponding SCSS file

const Hero = () => {
    return (
        <div className="hero">
            <h1>Welcome to My Loxone Integration App</h1>
            <p>Seamlessly connect your Loxone Miniserver with various smart home platforms.</p>
            {/* Add more content or buttons as needed */}
        </div>
    );
};

export default Hero;
