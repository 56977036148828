import React from 'react';
import './TuyaIntegration.scss'; // Import the corresponding SCSS file for styling

const TuyaIntegration = () => {
    return (
        <div className="tuya-integration">
            <h2>Tuya Cloud Integration with Loxone</h2>

            <h3>Overview</h3>
            <p>This guide provides the steps for integrating your Tuya Cloud devices with Loxone Miniserver through our service.</p>

            <h3>Prerequisites</h3>
            <ul>
                <li>Access to Loxone Miniserver.</li>
                <li>A registered account with our service.</li>
            </ul>

            <h3>Integration Steps</h3>
            <ol>
                <li><strong>Register Your Tuya Cloud Device:</strong> Log in to your account on our service's platform and register your Tuya Cloud device by providing the necessary details including accessKey, secretKey, and device_id.</li>
                <li>
                    <strong>Configure Your Loxone Miniserver:</strong>
                    <ol>
                        <li>Create a Virtual HTTP Output in the Loxone Config software.</li>
                        <li>Set the URL for the Virtual HTTP Output to the endpoint provided by our service. The URL format should be `http://your-service-url.com/tuya/[accessKey]/[secretKey]/devices/[device_id]/commands`.</li>
                        <li>For the 'Turn On' command, set the On URL using the same format, and in the Body, specify the command in JSON format, e.g., {`{"commands":[{"code":"switch","value":true}]}`}.</li>
                        <li>For the 'Turn Off' command, use the same URL format, and in the Body, specify the command in JSON format, e.g., {`{"commands":[{"code":"switch","value":false}]}`}.</li>
                    </ol>
                </li>
                <li><strong>Use Our Service's Dashboard:</strong> Utilize our user-friendly dashboard to manage your Tuya Cloud devices, view logs, and configure device settings.</li>
            </ol>

            <h3>Testing the Integration</h3>
            <p>Once set up, test the integration by sending commands through the Loxone Miniserver. Monitor the device's response and check the logs on our service's dashboard for real-time feedback.</p>

            <h3>Support and Assistance</h3>
            <p>If you encounter any issues or have questions, our support team is here to help you ensure a smooth integration experience.</p>
        </div>
    );
};

export default TuyaIntegration;
