import React from 'react';
import './pricing.scss'; // Import the corresponding SCSS file

const Pricing = () => {
    return (
        <div className="pricing">
            <h2>Pricing Plans</h2>
            <div className="pricing-details">
                <h3>Beta Version</h3>
                <p className="highlight">Currently Free!</p>
                <p>Enjoy full access to all features without any cost during our beta phase.</p>
                <div className="future-pricing">
                    <h3>Future Paid Plans</h3>
                    <p>Coming soon! We are committed to providing fair and very affordable pricing options to suit your needs.</p>
                </div>
            </div>
        </div>
    );
};

export default Pricing;
