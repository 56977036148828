import React from 'react';
import './donation.scss'; // Import the corresponding SCSS file

const Donation = () => {
    return (
        <div className="donation">
            <h2>Support Our Project</h2>
            <p>Your donations help us to keep improving.</p>
            <button>Donate Now</button>
        </div>
    );
};

export default Donation;
